@import "../../../styles/base/abstracts";

.quote-card-content {
  margin-top: auto;
}

.quote-card-content__overline {
  @include baseline-text;
  font-weight: $fw-semibold;
}

.quote-card-content__text-mod {
  padding-top: $spacing-xl;
  @include mq($bp-tablet) {
    padding-top: $spacing-xxxl;
  }
}

.quote-card-content__text {
  @include big-paragraph($ff-serif, $fw-light);
  position: relative;
  text-indent: -0.45em;
}

.quote-card-content__credit-mod {
  @include baseline-text;
  border-top: $border-light;
  display: flex;
  padding-top: $grid-padding-mobile;
  margin-top: $grid-padding-mobile * 2;
  justify-content: space-between;

  @include mq($bp-tablet) {
    padding-top: $grid-padding;
    margin-top: $grid-padding * 2;
  }
}
